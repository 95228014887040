import React from "react";
import "./Team.css";
import teamImg1 from "../../assets/images/team/team-img-1.jpg";
import { Header, Footer, HomeTechnologies } from "../../components/index";

const Team = ({show=true}) => {
  return (
    <>
      <section className="banner-team">
       {show &&
        <Header />
       }
        
      <div className="title">
        <h1>Our Team</h1>

        <div className="header-line"></div>
      </div>
      </section>

      <section className="team-sec">
        <div className="con">
          <div className="left">
            <div className="member-img">
              <img src={teamImg1} alt="team-member-image" />
            </div>
            <h5>Arsalan Shahzad</h5>
            <p>Founder & Executive Director</p>
          </div>

          <div className="right">
            <h1>Arsalan Shahzad</h1>
            <div className="card-line"></div>
            <h6>Founder & Executive Director</h6>
            <p>
              As a co-founder of Pluton, Arsalan uses his skills in software
              development to guide the company’s vision and strategy. His deep
              knowledge of technology helps shape our products and drive our
              growth. Arsalan's leadership is key to overcoming challenges and
              moving Pluton forward.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Team;
