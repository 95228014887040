import { BlockchainIcon, BlockchainIconWhite } from "../../assets/images/index";
import imageUrl from "utils/ImageUrl";
import { Link } from "react-router-dom";

const Card = ({ card_href, card_title, card_para, card_icon }) => {
  return (
    <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xsm-12">
      <div className="card">
        <div className="service-card-image">
          <img src={imageUrl(card_icon)} className="black" alt="BlockChain" />
          {/* <img src={BlockchainIconWhite} className="white" alt="BlockChain" /> */}
        </div>
        <div className="service-card-heading">
          <Link to={card_href}>
            <h2>{card_title}</h2>
          </Link>
        </div>
        <div className="service-card-para">
          <p>{card_para}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
