import React from "react";
import { Footer, Header } from "../../components";
import { CareerBanner } from "../career/Career";
import { Parallax } from "react-scroll-parallax";
import "./aboutus.scss";
import { Team } from "pages";

function AboutUs() {
  const para = (
    <h6 className="para">
      We're a <span className="color-primary"> group</span> of inventive
      masterminds who have assembled a
      <span className="color-primary"> business </span>to change
      <span className="color-primary"> Pakistan </span> and the world.
    </h6>
  );

  return (
    <>
      <div id="about" className="for-parallax-footer">
        {/* =========================banner============ */}
        <section id="banner">
          <CareerBanner page="about" para={para} />
        </section>
        <section id="about-content">
          <div className="container">
            <div className="sec-padding">
              <div className="row">
                <div className="col-lg-12">
                  <div className="main-about-para">
                    <p>
                      Pluton, a software house established in 2018. Our
                      experience started fully intent on utilizing
                      state-of-the-art innovation to carry receptiveness and
                      trustworthiness to the organization processes.
                    </p>
                    <p>
                      Pluton's group was created with extra innovative devotees
                      as a <strong> software company.</strong> We have faith in
                      framing organizations as opposed to clients to associate
                      our vision with theirs and to pursue a shared objective.
                    </p>{" "}
                    <p>
                      With capability, inventiveness, and advancement, we
                      understand your vision and assist you. you with
                      transforming it into the real world. By incorporating
                      innovation into corporate cycles and improving and scaling
                      specialized items, we desire to increment dependence.
                    </p>{" "}
                    <p>
                      Pluton is one of the most mind-blowing innovative and
                      technological service suppliers across the globe. Only
                      Pluton, an <strong> IT company</strong>, uses the best
                      solutions and provides technological approaches to solve
                      your issues. You can entrust Pluton Limited with your
                      dreams, as our group is gifted and experienced and
                      endeavors to give flawlessness. We've cooperated with the
                      best to bring best-in-class advances to our clients.
                    </p>{" "}
                    <p>
                      We have done a lot of{" "}
                      <strong> software consulting and development </strong> in
                      web and app development, games advancement, NFTs,
                      blockchain, ICOs, and so forth. Whether it is a CMS Web
                      plan and software improvement or custom PHP advancement,
                      WordPress improvement, Internet business web advancement,
                      SEO or SMM, Android App improvement, or iOS App
                      improvement, It is an application improvement, or it is a
                      coin and NFTs by utilizing Ethereum or Solana.
                    </p>
                    <p>
                      We have you covered! However, all of our prosperity would
                      be unwarranted on the off chance that it was not so much
                      for our organizer and founder,{" "}
                      <strong> Ajaz Ali Agha </strong>, and his prime supporter
                      and cofounder, <strong> Arsalan Shahzad.</strong> It is
                      their spirit of advancement and pushing the limits for
                      Pluton, which fills in as our most noteworthy inspiration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-5">
                <div className="col-lg-12">
                  <div className="heading">
                    <h2>We Know That Quality is the Best Business Strategy.</h2>
                    <hr className="line" />
                  </div>
                  <div className="para">
                    <p>
                      We believe in giving our clients the furthest down the
                      line, inventive answers with innovative solutions to give
                      them an upper hand. Aside from tweaked programming
                      advancements, We have also expanded our native
                      arrangements and have been very generally successful.
                      Welcome to the planet's markets.
                    </p>
                    <p>
                      We give you a wide scope of new advances and technology.
                      We have encountered and worked with them, and we have a
                      mastery of the Web. Design/Development, Graphics
                      Designing, ERP Solutions,{" "}
                      <strong> Software Development</strong>, App Improvement,
                      Game Development, <strong> Digital Solutions</strong> ,
                      Search Engine Optimization, 2D and 3D movements, NFT
                      planning and progression, content composition, space
                      facilitation enrollments, and a few other things.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-5">
                <div className="col-lg-12">
                  <div className="heading">
                    <h2>Future Perfect, Full Service, and Full Stack</h2>
                    <hr className="line" />
                  </div>
                  <div className="para">
                    <p>
                      You're putting away more than cash—it's also energy,
                      knowledge, brand, and the extend the capability of your
                      business. Pluton interprets this interest into a
                      significant effect. for yourself as well as your crowd.
                    </p>
                    <p>
                      Everything under one roof implies less gambling and a
                      durable group to guarantee it. positive progress. From
                      item system to item plan to full-stack design, Pluton
                      handles Your knowledge and insight bring about more than
                      an adequate award.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-5">
                <div className="col-lg-12">
                  <div className="heading">
                    <h2>Exact Delivery</h2>
                    <hr className="line" />
                  </div>
                  <div className="para">
                    <p>
                      Our groups never think twice about conveying the total
                      arrangement of products and{" "}
                      <strong> software solutions design/designing </strong> we
                      guarantee, when we guarantee it. Talented personnel
                      Frameworks Limited's specialized business experts give
                      imaginative thoughts. profound ability and the will to
                      succeed. Our group exceeds everyone's expectations. gives
                      absolute fulfillment. Your satisfaction is generally our
                      chief need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Team show={false}/>
      {/* <Footer /> */}
      <Parallax translateY={[-25, 40]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default AboutUs;
