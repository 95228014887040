import React, { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./service-banner.scss";
import { Header } from "../index";
import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { BannerBgLayer } from "../../assets/images/index";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
const useStyles = makeStyles({
  input: {
    color: "white",
  },
});
const ServiceBanner = ({ page, title, titleSmall, para }) => {
  const classes = useStyles();
  const [submitBtn, setSubmitBtn] = useState(false);
  const form = useRef();
  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSubmitBtn(!submitBtn);
    emailjs
      .sendForm(
        "service_e9b9pgq",
        "contact_form",
        form.current,
        "EmOv5QvdQDIqn2z4O"
      )
      .then(
        (result) => {
          console.log(result.text);
          // form.reset();
          toast.success("sucessfully submitted!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSubmitBtn(!submitBtn);
        },
        (error) => {
          toast.error(`${error.text}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(error.text);
        }
      );
    form.current.reset();
  };
  return (
    <div className="home-section section ">
      {/* <img
        src={BannerBgLayer}
        alt=""
        style={{
          zIndex: "-2",
          position: "absolute",
          height: "100vh",
          width: "100%",
        }}
      /> */}
      <div>
        <Header />
      </div>

      <div className="banner-container">
        <div className="sec-padding">
          <div className="row align-items-center">
            <div className="col-lg-6 d-flex  flex-column align-items-start justify-content-end ">
              <div className="title-home">
                <h1>{title}</h1>
                <hr className="line" />
                <h3>{titleSmall}</h3>
                <h6 className="para">{para}</h6>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form">
                <form
                  ref={form}
                  onSubmit={formSubmitHandler}
                  className="d-flex justify-content-end"
                >
                  <div className="main-form">
                    <div className="inner-from">
                      <div className="heading">
                        <h4>Request for proposal</h4>
                      </div>
                      <div className="fields">
                        {" "}
                        <TextField
                          name="user_name"
                          id="user_name"
                          label="Name"
                          variant="standard"
                          type={"text"}
                          required
                          sx={{
                            m: 1,
                            width: "500px",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                      <div className="fields">
                        {" "}
                        <TextField
                          id="email"
                          type={"email"}
                          label="Email"
                          variant="standard"
                          inputProps={{
                            pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                          }}
                          name="user_email"
                          required
                          sx={{
                            m: 1,
                            width: "500px",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                      <div className="fields">
                        {" "}
                        <TextField
                          id="phone"
                          label="Phone"
                          type={"text"}
                          variant="standard"
                          name="user_mobile"
                          required
                          sx={{
                            m: 1,
                            width: "500px",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                      <div className="fields">
                        {" "}
                        <TextField
                          id="message"
                          label="Message"
                          variant="standard"
                          name="user_message"
                          required
                          sx={{
                            m: 1,
                            width: "500px",
                            maxWidth: "100%",
                          }}
                          noValidate
                          autoComplete="off"
                        />
                      </div>

                      <div className="button">
                        <button
                          className="custom-btn"
                          type="submit"
                          disabled={submitBtn}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================toast-container================== */}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* ======================end===================== */}
    </div>
  );
};
export default memo(ServiceBanner);
